import { Controller } from 'stimulus';
import { ScrollSpy } from 'bootstrap';
import Typed from 'typed.js';

import HSHeader from '../front/hs-header';
import HSStickyBlock from '../front/hs-sticky-block';
import HSScrollSpy from '../front/hs-scrollspy';

export default class extends Controller {
  static targets = ['header', 'upper', 'inner', 'typed'];

  connect() {
    this.initHSHeader()
    this.animateWithTyped()
    this.initHSStickyBlock()
    this.initBootstrapScrollSpy()
    this.hsScrollSpy()
  }

  initHSHeader() {
    if(this.hasHeaderTarget) {
      new HSHeader(this.headerTarget).init()
    }
  }

  animateWithTyped() {
    if(this.hasTypedTarget) {
      const options = {
        'strings': ['ideal', 'fastest', 'convenient'],
        'typeSpeed': 60,
        'loop': true,
        'backSpeed': 25,
        'backDelay': 1500
      };
      new Typed(this.typedTarget, options);
    }
  }

  initHSStickyBlock() {
    if(this.hasInnerTarget) {
      new HSStickyBlock('.js-sticky-block', {
        targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
      })
    }
  }

  initBootstrapScrollSpy() {
    if(this.hasInnerTarget) {
      const options = {
        target: '#navbarSettings',
        offset: 10
      };
      new ScrollSpy(document.body, options);
    }
  }

  hsScrollSpy() {
    if (this.hasUpperTarget) {
      const options = {
        breakpoint: 'lg'
      }
      new HSScrollSpy('#navbarVerticalNavMenu', options);
    }
  }
}
