"use strict";
import ApexCharts from 'apexcharts'
import { keys, values, map } from 'lodash'

export const renderTopClientGraph = (element, currencyValue, clientData) => {
  return new ApexCharts(
    element,
    topClientGraphConfiguration(clientData, currencyValue)
  ).render();
}

function topClientGraphConfiguration(clientData, currency) {
  let client_names = keys(clientData);
  let client_amount = values(clientData);
  return {
    series: [{
      name: 'Amount',
      data: client_amount,
      show: false
    }],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        barHeight: 23
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      formatter: function(seriesName, opts) {
        var clientName = '';
        if (seriesName.length > 20) {
          clientName = [`${seriesName.substring(0,18)}` + '..'];
        } else {
          clientName = [seriesName];
        }
        return clientName;
      },
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function(val) {
          return `${currency}${val}`
        }
      }
    },
    colors: ['#3E97FF', '#F1416C', '#50CD89', '#FFC700', '#7239EA'],
    xaxis: {
      categories: client_names,
      labels: {
        formatter: function (val) {
          if (val >= 100000) {
            return val/1000 + 'K';
          } else {
            return val;
          }
        },
        style: {
          colors: '#BDBDBD',
          fontSize: '14px',
          fontWeight: '600',
          align: 'left'
        }
      },
      axisBorder: {
        show: true
      }
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: '#424242',
          fontSize: '14px',
          fontWeight: '600'
        },
        offsetY: 2,
        align: 'left'
      }
    },
    grid: {
      borderColor: '#00000000',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      strokeDashArray: 4
    }
  };
};
