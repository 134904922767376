"use strict";
import ApexCharts from 'apexcharts'
import { keys, values, max } from 'lodash'

export const renderSalesGraph = (element, currencyValue, data) => {
  return new ApexCharts(
    element,
    salesGraphConfiguration(data.revenue, currencyValue)
  ).render();
}

function salesGraphConfiguration(revenueData, currency){
  let months = keys(revenueData);
  let sales = values(revenueData);

  return {
    series: [{
      name: 'Total',
      data: sales
    }],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      width: '100%',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: false
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0.075
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: ['#00A3FF']
    },
    xaxis: {
      categories: months,
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      labels: {
        show: false
      },
      tickPlacement: 'between'
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function(val) {
          return `${currency}${val} sales`
        }
      }
    },
    colors: ['#00A3FF'],
    markers: {
      colors: ['#00A3FF'],
      strokeColor: ['#00A3FF'],
      strokeWidth: 3
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    grid: {
      borderColor: '#eff2f5',
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        left: -5,
        right: -10
      }
    }
  };
}
