import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['existingCard', 'useNewCard', 'paymentForm', 'cardElement', 'cardErrors', 'nameOnCard']

  connect() {
    if (this.hasCardElementTarget) this.setupStripe();
    if (this.hasUseNewCardTarget) {
      this.useNewCardTarget.addEventListener("click", function(event) {
        event.preventDefault();
        this.paymentFormTarget.classList.remove("d-none");
        this.existingCardTarget.classList.add("d-none");
      });
    }
  }

  setupStripe() {
    var stripe_key = document.querySelector("meta[name='stripe-public-key']").getAttribute("content");
    var stripe = Stripe(stripe_key);

    var elements = stripe.elements();
    var card = elements.create('card');
    card.mount(this.cardElementTarget);

    var displayError = this.cardErrorsTarget;
    card.addEventListener('change', function(event) {
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = this.paymentFormTarget;
    var paymentIntentId = form.dataset.paymentIntent;
    var setupIntentId = form.dataset.setupIntent;
    var name = this.nameOnCardTarget.value;

    if (paymentIntentId) {
      if (form.dataset.status == 'requires_action') {
        stripe.confirmCardPayment(paymentIntentId, { setup_future_usage: 'off_session' }).then(function(result) {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('d-none');
          } else {
            form.submit();
          }
        });
      }
    }

    var that = this;

    form.addEventListener('submit', function(event) {
      event.preventDefault();
      var data = {
        payment_method_data: {
          card: card,
          billing_details: {
            name: name,
          }
        }
      };

      if (paymentIntentId) {
        stripe.confirmCardPayment(paymentIntentId, {
          payment_method: data.payment_method_data,
          setup_future_usage: 'off_session',
          save_payment_method: true
        }).then(function(result) {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('d-none');
          } else {
            form.submit();
          }
        });
      } else if (setupIntentId) {
        stripe.confirmCardSetup(setupIntentId, {
          payment_method: data.payment_method_data
        }).then(function (result) {
          if (result.error) {
            displayError.textContent = result.error.message;
          } else {
            that.addHiddenField(form, "payment_method_id", result.setupIntent.payment_method);
            form.submit();
          }
        });
      } else {
        data.payment_method_data.type = 'card';
        stripe.createPaymentMethod(data.payment_method_data).then(function(result) {
          if (result.error) {
            displayError.textContent = result.error.message;
          } else {
            that.addHiddenField(form, "payment_method_id", result.paymentMethod.id);
            form.submit();
          }
        });
      }
    });
  }

  addHiddenField(form, name, value) {
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', name);
    hiddenInput.setAttribute('value', value);
    form.appendChild(hiddenInput);
  }
}
