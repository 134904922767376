import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['password', 'icon'];

  toggle(event) {
    this.handlePasswordField();
  }

  handlePasswordField() {
    this.passwordTargets.forEach((passTarget, i) => {
      if(passTarget.type === 'password'){
        passTarget.type = 'text';
        this.iconTargets.forEach((icon, i) => {
          icon.classList.remove('fa-eye-slash');
          icon.classList.add('fa-eye');
        });
      } else if(passTarget.type === 'text'){
        passTarget.type = 'password';
        this.iconTargets.forEach((icon, i) => {
          icon.classList.remove('fa-eye');
          icon.classList.add('fa-eye-slash');
        });
      }
    });
  }
}
