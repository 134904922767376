//////////////////////////////////////////////
// TODO: Currently used in old receipts form//
// To be deleted with removal of old invoice//
// form                                     //
//////////////////////////////////////////////

import Rails from '@rails/ujs';
import { Controller } from "stimulus";
import { Modal } from "bootstrap";
import TomSelect from "tom-select";

let myModal = null;
let selectizeCallback = null;

export default class extends Controller {

  connect() {
    this.enableTS();
  }

  disconnect() {
    const myModalEl = document.getElementById('clientModal');
    if (myModal) myModal.hide();
    const clientForm = document.getElementById('new_client');
    if(myModalEl) {
      myModalEl.addEventListener('hidden.bs.modal', function (event) {
        if (selectizeCallback !== null) {
          selectizeCallback();
          selectizeCallback = null;
        }
        clientForm.reset();
        Rails.enableElement(clientForm);
      });
      this.enableTS();
      this.changeCurrency();
    }
  }

  changeCurrency() {
    const clientDropdown = document.getElementById('receipt_client_id');
    const selectedCurrency = clientDropdown.selectedOptions[0].dataset.currencySymbol;
    const currencyDropdown = document.getElementById('receipt_currency');
    [...currencyDropdown.options]
      .filter(x => x.value === clientDropdown.selectedOptions[0].dataset.currencyCode)[0]
      .setAttribute("selected", true)
    this.updateCurrenciesInSummaryBox(selectedCurrency);
  }

  updateCurrenciesInSummaryBox(symbol) {
    const targets = document.querySelectorAll('[data-receipt-target="currentCurrency"]')

    targets.forEach((target, _i) => {
      target.textContent = `${symbol}`
    });
  }

  renderItemOption(data) {
    const item = data[0];
    const escape = data[1];
    return `<div>${escape(item.text)}</div>`;
  }

  enableTS() {
    let renderOption = () => (...args) => this.renderItemOption(args);

    var element = document.getElementById('receipt_client_id');
    if (element.tomselect === undefined) {
      new TomSelect(element, {
        plugins: ['remove_button'],
        render: {
          option: renderOption(),
          item: renderOption()
        },
        create: function(input, callback) {
          selectizeCallback = callback;
          myModal = new Modal(document.getElementById('clientModal'), {
            keyboard: false
          });
          myModal.show();
          const name = document.getElementById('client_full_name');
          name.value = input;
        }
      });
    }
  }
}
