"use strict";

export const createInputField = (...args) => {
  const property = {
    classes: args[0] || ['form-control', 'form-control-solid', 'form-control-sm'],
    ids: args[1],
    value: args[2],
    placeHolder: args[3],
    name: args[4] || '',
    type: args[5] || 'text'
  }

  var input = document.createElement('INPUT');
  input.type = property.type;
  property.classes.forEach((klass, _i) => {
    input.classList.add(klass)
  });
  input.id = property.ids;
  input.placeholder = property.placeHolder;
  input.name = property.name;
  input.value = property.value;
  input.required = true;
  return input;
}

export const hiddenInputField = (...args) => {
  var input = document.createElement('INPUT');
  input.type = 'hidden';
  input.name = args[0];
  input.value = args[1];
  input.id = args[2];
  return input;
}

export const createUnitSelectTag = (...args) => {
  var units = {
    "kg": "KG",
    "pc": "Pc",
    "lb.": "Lb",
    "ft": "Ft",
    "hrs": "Hrs",
    "d": "D",
    "m": "M",
    "y": "Y"
  };
  const property = {
    classes: args[0] || ['form-select', 'form-select-solid', 'form-select-sm'],
    ids: args[1],
    value: args[2] || '',
    placeholder: args[3].placeHolder,
    name: args[4]
  }

  var selectTag = document.createElement('SELECT');
  selectTag.id = property.ids;
  selectTag.placeholder = property.placeHolder;
  selectTag.name = property.name;
  property.classes.forEach((klass, _i) => {
    selectTag.classList.add(klass)
  });
  selectTag.selected = args[2];
  selectTag.required = true;

  Object.entries(units).forEach(([key, value]) => {
    var option = document.createElement('option');
    option.setAttribute('value', key);
    option.text = value;
    if (args[2] == key) {
      option.selected = 'true'
    }
    selectTag.options.add(option);
  });
  return selectTag;
}

export const deleteBtn = () => {
  var anchor = document.createElement('A');
  ['btn', 'btn-icon', 'btn-light-google', 'btn-sm'].forEach((klass, _i) => {
    anchor.classList.add(klass);
  });
  var icon = document.createElement('I');
  ['fas', 'fa-trash-alt', 'fs-4'].forEach((klass, _i) => {
    icon.classList.add(klass);
  });
  anchor.appendChild(icon);

  return anchor;
}

export const taxDiscountSelectTag = (...args) => {
  const property = {
    classes: args[0] || ['form-select', 'form-select-solid', 'form-select-sm'],
    ids: args[1],
    placeholder: args[3],
    name: args[4],
    selected: args[5]
  }

  var selectTag = document.createElement('SELECT');
  selectTag.id = property.ids;
  selectTag.placeholder = property.placeholder;
  selectTag.name = property.name;
  selectTag.multiple = true;
  property.classes.forEach((klass, _i) => {
    selectTag.classList.add(klass)
  });

  Object.entries(args[2]).forEach(([key, value]) => {
    var optGroup = document.createElement('OPTGROUP');
    optGroup.setAttribute('label', key);
    value.forEach((item, i) => {
      var option = document.createElement('option');
      option.setAttribute('data-name', item.name);
      option.setAttribute('data-td-type', item.td_type);
      option.setAttribute('data-td-amount', item.amount);
      option.setAttribute('value', item.id);
      option.text = item.name;
      if (property.selected) {
        property.selected.forEach((selected, i) => {
          if (selected.tax_and_discount_id == item.id) option.setAttribute('selected', 'selected');
        });
      }

      optGroup.appendChild(option);
    });
    selectTag.appendChild(optGroup);
  });

  return selectTag;

}

export const simpleSelectTag = (...args) => {
  const properties = Object.assign({}, args[0]);
  var selectTag = document.createElement('SELECT');
  selectTag.id = properties.id;
  selectTag.placeholder = properties.placeHolder;
  selectTag.name = properties.name;
  selectTag.required = true;
  properties.classes.forEach((klass, _i) => {
    selectTag.classList.add(klass)
  });
  Object.entries(properties.options).forEach(([key, value]) => {
    var option = document.createElement('option');
    option.setAttribute('value', value);
    option.text = key;
    if (properties.selected == value) {
      option.selected = 'true'
    }
    selectTag.options.add(option)
  });
  return selectTag;
}
