import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static values = { mode: String }
  static targets = ['date'];

  connect() {
    this.enableDatePicker();
  }

  enableDatePicker() {
    flatpickr(this.dateTarget, this.options());
  }

  options() {
    var opt = {
      autoclose: true
    }
    if (this.hasModeValue) {
     if(this.modeValue === 'date') {
       opt.dateFormat = 'Y-m-d'
     } else if (this.modeValue === 'datetime') {
       opt.dateFormat = 'Y-m-d H:i',
       opt.enableTime = true
     } else if (this.modeValue === 'range') {
       opt.dateFormat = 'Y-m-d';
       opt.mode = this.modeValue;
     }
    }
    return opt
  }
}
