import { Controller } from "stimulus"

const NEW_RECEIPT_PATH = '/invoices/new';
const RECEIPTS_PATH = '/invoices';
const LI_SETTING_PATH = '/invoices/setting';
const TAX_AND_DISCOUNTS_PATH = '/invoices/tax_and_discounts';

export default class extends Controller {
  static classes = ['here', 'show', 'hover'];
  static targets = ['new', 'all', 'sub', 'li', 'td'];

  connect() {
    if (location.pathname === NEW_RECEIPT_PATH) {
      this.highlightMainMenu();
      this.highlightSubMenu();
      this.newTarget.classList.add('here');
      this.newTarget.classList.add('show');
    } else if (location.pathname === RECEIPTS_PATH) {
      this.highlightMainMenu();
      this.highlightSubMenu();
      this.allTarget.classList.add('here');
      this.allTarget.classList.add('show');
    } else if (location.pathname.includes(LI_SETTING_PATH)) {
      this.highlightMainMenu();
      this.highlightSubMenu();
      this.liTarget.classList.add('here');
      this.liTarget.classList.add('show');
    } else if (location.pathname === TAX_AND_DISCOUNTS_PATH) {
      this.highlightMainMenu();
      this.highlightSubMenu();
      this.tdTarget.classList.add('here');
      this.tdTarget.classList.add('show');
    }
  }

  highlightMainMenu(){
    this.element.classList.add(this.hoverClass);
    this.element.classList.add(this.showClass);
  }

  highlightSubMenu(){
    this.subTarget.classList.add(this.showClass);
  }
}
