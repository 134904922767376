import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import TomSelect from 'tom-select';
import { Modal } from "bootstrap";
import flatpickr from 'flatpickr';
import { get } from '@rails/request.js';

let myModal = null;
let selectizeCallback = null;

export default class extends Controller {
  static values = { currency: String, currencyCode: String, id: String };
  static targets = [
    'liSelect', 'liDefaultMessage', 'liTd', 'tdSelect',
    'paymentDate', 'form', 'liDelete', 'tdDeleteApplicables',
    'customCurrency', 'currentCurrency', 'client', 'clientContainer' ];

  connect() {
    this.enableTomSelects();
  }

  // Enable tomselect on line item dropdown and tax_or_discount dropdown
  enableTomSelects() {
    this.ts(this.liSelectTarget);
    this.tstd(this.tdSelectTarget);
    this.enableTsClient(this.clientTarget);
  }

  sanitize(string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match)=>(map[match]));
  }

  optionAdd(id) {
    const receiptId = this._receiptId();

    let url = '';
    if(isNaN(receiptId)) {
      url = `/invoices/form_actions/line_item`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/line_item`;
    }

    if(isNaN(id)) {
      url = `${url}?attr=${new URLSearchParams(id).toString()}`;
    } else if(isNaN(id) == false) {
      url = `${url}?product_id=${id}`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  submitForm(dataParams, receiptId) {
    var queryParams = Object.keys(dataParams).map(k => `${k}=${encodeURI(dataParams[k])}`).join('&');
    let url = '';
    if(receiptId == 0) {
      url = `/invoices/form_actions/line_item?${queryParams}`
    } else if (receiptId > 0) {
      url = `/invoices/form_actions/${receiptId}/line_item?${queryParams}`
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (receiptId > 0) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  addLiTdChange(tdId, el) {
    var receiptId = el.dataset.receiptId || 0;
    var data = {
      'tdId': tdId,
      'perform': 'add',
      'item_name': el.dataset.itemName
    };
    if (el.dataset.productId) {
      data.product_id = el.dataset.productId
    }
    this.submitForm(data, receiptId);
  }

  removeLiTdChange(tdId, el) {
    var receiptId = el.dataset.receiptId || 0;
    var data = {
      'product_id': el.dataset.productId,
      'tdId': tdId,
      'perform': 'remove',
      'itemId': el.dataset.itemId,
    };
    this.submitForm(data, receiptId);
  }

  liTdTargetConnected(element) {
    let eventAddHandler = () => (...args) => this.addLiTdChange(args[0], element);
    let eventRemoveHandler = () => (...args) => this.removeLiTdChange(args[0], element);
    new TomSelect(element, {
      onItemAdd: eventAddHandler(),
      onItemRemove: eventRemoveHandler(),
      closeAfterSelect: true,
      plugins: ['checkbox_options']
     });
  }

  // tomselect configurations
  // param item is a target
  ts(item) {
    let eventHandler = () => (...args) => this.optionAdd(...args);
    this.tsControl = new TomSelect(item, {
      create: true,
      persist: true,
      onItemAdd: eventHandler(),
      closeAfterSelect: true,
      render: {
        option: function (item, escape) {
          return `<div>${escape(item.text)}</div>`
        },
        item: function (item, escape) {
          return `<div id="item_${escape(item.$id)}">${escape(item.text)}</div>`
        }
      }
    });
    return this.tsControl;
  }

  taxesAdd(id) {
    const receiptId = this._receiptId();
    let url = ''
    // if current url is of new invoice form then receiptId will be
    // equals to new otherwise it will be a receipt id (an integer)
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/tax_and_discount?tax_and_discount_id=${id}`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/tax_and_discount?tax_and_discount_id=${id}`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  tstd(item) {
    let eventHandler = () => (...args) => this.taxesAdd(...args);
    this.tstdControl = new TomSelect(item, {
      create: true,
      persist: true,
      onItemAdd: eventHandler(),
      closeAfterSelect: true,
      render: {
        option: function (item, escape) {
          return `<div>${escape(item.text)}</div>`
        },
        item: function (item, escape) {
          return `<div id="item_${escape(item.$id)}">${escape(item.text)}</div>`
        }
      }
    });
    return this.tstdControl;
  }


  dateChange(event) {
    var status = event.target.selectedOptions[0].value;
    const receiptId = this._receiptId();
    let url = ''
    // if current url is of new invoice form then receiptId will be
    // equals to new otherwise it will be a receipt id (an integer)
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/status?status=${status}`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/status?status=${status}`
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='post';
      }
      this.formTarget.requestSubmit();
    }
  }

  handleChange(event) {
    const receiptId = this._receiptId();
    let url = ''
    // if current url is of new invoice form then receiptId will be
    // equals to new otherwise it will be a receipt id (an integer)
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/line_item`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/line_item`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  // Used for delete button in a line item
  handleDelete(event) {
    event.preventDefault();
    const lidelete = event.currentTarget.id.split('_')[2];
    const li = this.liDeleteTargets[lidelete];
    li.value = true;

    const receiptId = this._receiptId();
    let url = ''
    // if current url is of new invoice form then receiptId will be
    // equals to new otherwise it will be a receipt id (an integer)
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/line_item`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/line_item/`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  taxChange(event) {
    const receiptId = this._receiptId();
    let url = ''
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/tax_and_discount`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/tax_and_discount`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  taxDelete(event) {
    event.preventDefault();
    const deleteApplicable = event.currentTarget.id.split('_')[2];
    const td = this.tdDeleteApplicablesTargets[deleteApplicable];
    td.value = true;

    const receiptId = this._receiptId();
    let url = ''
    // if current url is of new invoice form then receiptId will be
    // equals to new otherwise it will be a receipt id (an integer)
    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/tax_and_discount`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/tax_and_discount`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  handleCurrency(event) {
    const receiptId = this._receiptId();
    const custom_currency = this.customCurrencyTarget.selectedOptions[0].dataset.currencyCode;
    let url = ''

    if (isNaN(receiptId)) {
      url = `/invoices/form_actions/general_actions?custom_currency=${custom_currency}`;
    } else if (isNaN(receiptId) == false) {
      url = `/invoices/form_actions/${receiptId}/general_actions?custom_currency=${custom_currency}`;
    }

    if (url !== undefined) {
      this.formTarget.action = url;
      if (isNaN(receiptId) == false) {
        this.formTarget.firstElementChild.value='patch';
      }
      this.formTarget.requestSubmit();
    }
  }

  // Handles change in client's dropdown and in future for status as well
  handleGeneralUpdate(event) {
    const clientId = this.clientTarget.selectedOptions[0].value;

    if(clientId !== '') {
      const receiptId = this._receiptId();
      let url = ''

      if (isNaN(receiptId)) {
        url = `/invoices/form_actions/general_actions`;
      } else if (isNaN(receiptId) == false) {
        url = `/invoices/form_actions/${receiptId}/general_actions`;
      }

      if (url !== undefined) {
        this.formTarget.action = url;
        if (isNaN(receiptId) == false) {
          this.formTarget.firstElementChild.value='patch';
        }
        this.formTarget.requestSubmit();
      }
    }
  }

  /////////////////////////////////////////
  // Client dropdown handler //////////////
  /////////////////////////////////////////

  renderItemOption(data) {
    const item = data[0];
    const escape = data[1];
    return `<div>${escape(item.text)}</div>`;
  }

  enableTsClient(element) {
    let renderOption = () => (...args) => this.renderItemOption(args);

    if (element.tomselect === undefined) {
      this.tsControl = new TomSelect(element, {
        plugins: ['remove_button'],
        render: {
          option: renderOption(),
          item: renderOption()
        },
        create: function(input, callback) {
          selectizeCallback = callback;
          myModal = new Modal(document.getElementById('clientModal'), {
            keyboard: false
          });
          myModal.show();
          const name = document.getElementById('client_full_name');
          name.value = input;
        }
      });
    }
    return this.tsControl;
  }

  clientContainerTargetDisconnected(element) {
    const myModalEl = document.getElementById('clientModal');
    if (myModal) myModal.hide();
    const clientForm = document.getElementById('new_client');
    if(myModalEl) {
      myModalEl.addEventListener('hidden.bs.modal', function (event) {
        if (selectizeCallback !== null) {
          selectizeCallback();
          selectizeCallback = null;
        }
        clientForm.reset();
        Rails.enableElement(clientForm);
      });
      this.handleGeneralUpdate();
    }
  }

  _receiptId() {
    return window.location.pathname.split('/')[2];
  }
}
