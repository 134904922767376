import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'
import { forEach, keys, values, max } from "lodash"
import { renderSalesGraph } from '../utility/sales_graph_helper'
import { renderTopClientGraph } from '../utility/top_client_graph_helper'
import { renderTopPerformingProductsGraph } from '../utility/top_performing_products_graph_helper'
import { GraphTooltipTemplate } from '../utility/graph_tooltip_template'
import { EarningGraphHandler } from '../utility/earning_graph_handler'
import { ProductGraphHandler } from '../utility/product_graph_handler'

export default class extends Controller {
  static targets = [
    'graphContainer', 'loader',
    'salesGraph', 'earningsGraph', 'productsGraph', 'productsGraphLoader',
    'totalRevenue', 'salesProfitBadge', 'salesLossBadge', 'salesNeutralBadge', 'revenuePercentage',
    'earningHeader', 'sellingAmount', 'earningProfitBadge', 'earningPercentage', 'earningLossBadge', 'earningNeutralBadge',
    'selectedMonth', 'backBtn', 'productsGraphCaption', 'topPayingClientGraph', 'topPerformingProductsGraph'
  ];

  static values = {
    currency: String
  }

  connect() {
    setTimeout(() => {
      this.graphContainerTarget.width = '100%';
      this.renderGraphs();
    }, 1000);
  }

  renderGraphs() {
    fetch('/orders/graphs', {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      forEach(this.loaderTargets, (loader, _i) => {
        this.hideLoader(loader);
      });
      response.json().then((data) => {
        this.renderSalesGraphComponent(data.sales);
        this.renderEarningGraphComponent(data.earnings);
        this.renderTopClientGraphComponent(data.top_paying_clients);
        this.renderPerformingProductsGraphComponent(data.top_performing_products);
      });
    });
  }

  renderSalesGraphComponent(data) {
    forEach(this.revenuePercentageTargets, (percentage, _i) => {
      percentage.innerHTML = data.revenue_percentage;
    });

    // renders % profit or loss and initializes apex chart for sales graph
    this.totalRevenueTarget.innerHTML = `${parseFloat(data.total_revenue).toFixed(1)}`;
    if (data.revenue_percentage > 0) {
      this.salesProfitBadgeTarget.style = 'display: block';
    } else if(data.revenue_percentage < 0) {
      this.salesLossBadgeTarget.style = 'display: block';
    } //else if(data.revenue_percentage === 0) {
    //   this.salesNeutralBadgeTarget.style = 'display: block';
    // }
    renderSalesGraph(this.salesGraphTarget, this.currencyValue, data)
  }

  renderEarningGraphComponent(data) {
    let handler = this.initializeHandler(data);
    handler.setup();
    this.renderEarningGraphs(handler, data);
  }

  renderTopClientGraphComponent(data){
    renderTopClientGraph(this.topPayingClientGraphTarget, this.currencyValue, data);
  }

  renderPerformingProductsGraphComponent(data){
    renderTopPerformingProductsGraph(this.topPerformingProductsGraphTarget, data);
  }

  initializeHandler(data) {
    return new EarningGraphHandler(this.controllerTargets(), data);
  }

  renderEarningGraphs(handlerObj, data) {
    handlerObj.prepareEarningGraphToRender();
    var earningsGraph = new ApexCharts(
      this.earningsGraphTarget,
      this.earningGraphOptions(
        data.months, data.costs,
        data.sellings, data.profits,
        handlerObj
      )
    ).render();
  }

  back(event) {
    this.earningHeaderTarget.style = 'display:block';
    this.earningsGraphTarget.style = 'display:block';
    this.productsGraphTarget.style = 'display:none';
    this.productsGraphCaptionTarget.style = 'display: none';
    this.selectedMonthTarget.innerHTML = 'Total income';
    this.backBtnTarget.style = 'display:none';
  };

  displayLoader(target) {
    target.classList.remove('loader-hide');
    target.classList.add('loader-display');
  }

  hideLoader(target) {
    target.classList.remove('loader-display');
    target.classList.add('loader-hide');
  }

  earningGraphOptions(months, cost, selling, profits, handlerObj) {
    handlerObj.displayGrowth();
    const that = this;
    return {
      series: [{
        name: 'Cost Price',
        data: cost,
        margin: {
          left: 5,
          right: 5
        }
      }, {
        name: 'Selling Price',
        data: selling
      }],
      chart: {
        fontFamily: 'inherit',
        height: 325,
        type: 'bar',
        width: '100%',
        toolbar: {
          show: false
        },
        events: {
          click: function(event, chartContext, config) {
            if(config.dataPointIndex !== -1) {
              that.displayLoader(that.productsGraphLoaderTarget);
              const selectedMonth = months[config.dataPointIndex];
              fetch(`/orders/monthly_data?month=${selectedMonth}`, {
                method: 'get',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json; charset=UTF-8'
                }
              })
              .then((response) => {
                response.json().then((data) => {
                  that.hideLoader(that.productsGraphLoaderTarget);
                  let productHandleObj = new ProductGraphHandler(that.controllerTargets(), data, that.currencyValue);
                  productHandleObj.setup(selectedMonth);
                  let productsGraph = new ApexCharts(that.productsGraphTarget, that.productsGraphOptions(data.productNames, data.cost, data.selling, that.currencyValue, data.profits, selectedMonth));
                  productsGraph.render();
                });
              });
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ['50%'],
          borderRadius: 2
        },
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: months,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          style: {
            colors: '#00A3FF',
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: '#00A3FF',
            fontSize: '12px'
          }
        }
      },
      fill: {
        type: 'solid'
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px'
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          let tootltip = new GraphTooltipTemplate(months[dataPointIndex], data, profits[dataPointIndex], seriesIndex, that.currencyValue);
          return tootltip.tooltip();
        }
      },
      colors: ['#00A3FF', '#E4E6EF'],
      grid: {
        borderColor: '#eff2f5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          left: -10,
          right: 5
        }
      }
    };
  }

  productsGraphOptions(productNames, cost, selling, currencyValue, profits, month) {
    const that = this;

    return {
      series: [{
        name: 'Cost Price',
        data: cost,
        margin: {
          left: 5,
          right: 5
        }
      }, {
        name: 'Selling Price',
        data: selling
      }],
      chart: {
        fontFamily: 'inherit',
        height: 325,
        type: 'bar',
        width: '100%',
        toolbar: {
          show: false
        },
        chart: {
          events: {
            click: function(event, chartContext, config) {
              const selectedProduct = productNames[config.dataPointIndex];
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ['50%'],
          borderRadius: 4
        },
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: productNames,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: '#00A3FF',
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: '#00A3FF',
            fontSize: '12px'
          }
        }
      },
      fill: {
        type: 'solid'
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px'
        },
        y: {
          formatter: function (val) {
            return `${currencyValue}${val} revenue`
          }
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w}) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          let tootltip = new GraphTooltipTemplate(productNames[dataPointIndex], data, profits[dataPointIndex], seriesIndex, that.currencyValue);
          return tootltip.tooltip();
        }
      },
      colors: ['#00A3FF', '#E4E6EF'],
      grid: {
        borderColor: '#eff2f5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          left: -10,
          right: 5
        }
      }
    };
  };

  controllerTargets() {
    return {
      earning: {
        graphEl: this.earningsGraphTarget,
        mainTitleEl: this.earningHeaderTarget,
        sellingAmountEl: this.sellingAmountTarget,
        profitBadgeEl: this.earningProfitBadgeTarget,
        lossBadgeEl: this.earningLossBadgeTarget,
        neutralBadgeEl: this.earningNeutralBadgeTarget,
        percentageAmountEls: this.earningPercentageTargets
      },
      product: {
        graphEl: this.productsGraphTarget,
        selectedMonthEl: this.selectedMonthTarget,
        backBtnEl: this.backBtnTarget,
        captionEl: this.productsGraphCaptionTarget
      }
    };
  }
}
