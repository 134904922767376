"use strict";

import { forEach } from "lodash"

export class EarningGraphHandler {
  constructor(targets, data) {
    this.els = targets;
    this.data = data;
  }

  setup() {
    this.els.earning.sellingAmountEl.innerHTML = `${parseFloat(this.data.total_selling).toFixed(1)}`;
    forEach(this.els.earning.percentageAmountEls, (percentage, _i) => {
      percentage.innerHTML = this.data.selling_percentage;
    });
  }

  prepareEarningGraphToRender() {
    this.els.product.graphEl.innerHTML = '';
    this.els.earning.graphEl.innerHTML = '';
    this.els.product.captionEl.style = 'display: none';
  }

  displayGrowth() {
    let cost = parseFloat(this.data.total_cost);
    let selling = parseFloat(this.data.total_selling);

    if (cost < selling) {
      this.els.earning.profitBadgeEl.style = 'display:block';
    } else if (cost > selling) {
      this.els.earning.lossBadgeEl.style = 'display:block';
    } else if (cost === selling){
      this.els.earning.neutralBadgeEl.style = 'display: block';
    }
  }
}
