import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'
import { map, values, keys } from 'lodash'

export default class extends Controller {
  static targets = ['scatterGraph', 'lineGraph'];
  static values = { clientUrl: String }

  connect() {
    this.fetchDataForGraph();
  }

  fetchDataForGraph() {
    fetch(this.clientUrlValue, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      response.json().then((data) => {
        var scatterGraphChart = new ApexCharts(this.scatterGraphTarget, this.scatterGraphOptions(data.client_payments_frequency));
        var lineGraphChart = new ApexCharts(this.lineGraphTarget, this.countGraphOptions(data.client_receipts_count));
        scatterGraphChart.render();
        lineGraphChart.render();
      });
    });
  }

  scatterGraphOptions(responseData) {
    return {
      chart: {
        type: 'scatter',
        height: '300px',
        toolbar: {
          show: false
        }
      },
      colors: ['#66DA26', '#D9534F', '#20c997'],
      series: [
        {
          name: 'Early payments',
          data: responseData[1]
        },
        {
          name: 'Late payments',
          data: responseData[0]
        },
        {
          name: 'Ontime payments',
          data: responseData[2]
        },
      ],
      fill: {
        type: 'solid',
        opacity: 1
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: ['#008BD9', '#47BE7D']
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: '#A1A5B7',
            fontSize: '12px',
          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '10px'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A1A5B7',
            fontSize: '12px',
          }
        },
        tooltip: {
          enabled: false,
          offsetX: 0,
          style: {
            fontSize: '10px'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      tooltip: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: '12px',

        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return '<div>' +
            '<div class="scatter-tooltip-header"><b>Delay</b>: ' +
            + data.y +
            ' days</div>' +
            '<div class="scatter-tooltip-body">' +
            '<div class="scatter-content">' +
            '<b>Amount</b>: ' + data.amount +
            '<br><b>Payment date</b>: ' + data.x +
            '<br><b>Due Date </b>:' + data.due_date +
            '</div>'+
            '</div>'+
            '</div>';
        }
      },
      grid: {
        borderColor: '#EFF2F5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      legend: {
        show: true,
        labels: {
          colors: '#A1A5B7',
          fontSize: '12px',
        }
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: '#008BD9',
          width: 1,
          dashArray: 3
        }
      },
    }
  }

  countGraphOptions(responseData) {
    return {
      series: [{
        name: 'Paid',
        data: values(responseData)
      }],
      chart: {
        type: 'area',
        height: '300px',
        toolbar: {
          show: false
        }
      },
      legend: {
        show: true
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'solid',
        opacity: 1
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: ['#008BD9', '#47BE7D']
      },
      xaxis: {
        categories: keys(responseData),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: '#A1A5B7',
            fontSize: '12px'
          }
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: '#008BD9',
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A1A5B7',
            fontSize: '12px',
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + " invoices"
          }
        }
      },
      colors: ['#F1FAFF', '#E8FFF3'],
      grid: {
        borderColor: '#EFF2F5',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        colors: ['#F1FAFF', '#E8FFF3'],
        strokeColor: ['#008BD9', '#47BE7D'],
        strokeWidth: 3
      }
    };
  }
}
