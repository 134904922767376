import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['frame'];

  remove(e) {
    e.preventDefault();
    this.frameTarget.innerHTML = "";
  }
}
