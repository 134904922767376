import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { forEach } from "lodash";
import { createInputField, deleteBtn, hiddenInputField, simpleSelectTag } from 'packs/utility/forms_helper';

export default class extends Controller {
  static targets = [ 'input', 'output', 'body', 'msg' ];
  static values = { applicables: String };
  tsControl = null;

  connect() {
    this.enableTs;
    if (!this.hasOutputTarget) {
      this.insertMessageBox();
    }
    this.reBuildForm();
  }

  reBuildForm() {
    if (this.hasApplicablesValue) {
      const appls = JSON.parse(this.applicablesValue);
      forEach(appls, (item, _i) => {
        this.insertExistingTr(item)
      });
      const event = document.createEvent("CustomEvent");
      event.initCustomEvent("inner-connected", true, true, null);
      window.dispatchEvent(event);
    }
  }

  insertExistingTr(td) {
    this.removeMsgBox()
    var row = this.createRowAndInsertCells();
    this.tdName(td.name, row.cells[0]);
    this.amount(td.amount, row.cells[1]);
    this.tdType(td.td_type, row.cells[2]);
    this.desc(td.description, row.cells[3]);
    this.hiddenProductIdField(td.tax_and_discount_id, row);
    if (location.pathname !== '/receipts/new') {
      this.hiddenIdField(td.id, row);
    }
    this.actns(td.id, row.cells[4]);
  }

  hiddenIdField(tdId, row) {
    var input = hiddenInputField(
      `receipt[td_applicables_attributes][${this.rowNo}][id]`,
      tdId,
      `receipt_td_applicables_attributes_${this.rowNo}_tax_and_discount_id`
    )
    row.appendChild(input);
  }

  insertNewTr(args, uniqueId) {
    this.removeMsgBox();
    var row = this.createRowAndInsertCells();
    var opts = [];
    [opts[0], opts[1], opts[2]] = args.innerText.split(';');
    if (['%', 'percentage'].includes(opts[2])){
      opts[2] = 'percentage'
    } else if (['-', 'fixed'].includes(opts[2])){
      opts[2] = 'fixed'
    }
    if(opts[1] == undefined) opts[1] = 0.0;
    opts[3] = '';
    //Maintaining original text in order to match the id with the delete button
    opts[4] = args.innerText;
    this.tdName(opts[0], row.cells[0]);
    this.amount(opts[1], row.cells[1]);
    this.tdType(opts[2], row.cells[2]);
    this.desc(decodeURIComponent(opts[3]), row.cells[3]);
    this.actns(opts[4], row.cells[4]);
  }

  insertTr(args) {
    this.removeMsgBox()
    var row = this.createRowAndInsertCells();
    this.tdName(args.name, row.cells[0]);
    this.amount(args.amount, row.cells[1]);
    this.tdType(args.tdType, row.cells[2]);
    this.desc(decodeURIComponent(args.description), row.cells[3]);
    this.hiddenProductIdField(args.value, row);
    this.actns(args.value, row.cells[4]);
  }

  // FIXME: Pass an object to form helper methods like tdType

  hiddenProductIdField(productId, row) {
    var input = hiddenInputField(
      `receipt[td_applicables_attributes][${this.rowNo}][tax_and_discount_id]`,
      productId,
      `receipt_td_applicables_attributes_${this.rowNo}_tax_and_discount_id`
    )
    row.appendChild(input);
  }

  createRowAndInsertCells() {
    const tBody = this.bodyTarget;
    const row = tBody.insertRow(this.rowNo);
    row.dataset.taxesTarget = 'output';
    var i = 0;
    while (i < 5) {
      row.insertCell(i);
      i++;
    }
    return row;
  }

  tdName(name, cell) {
    var input = createInputField(
      '',
      `receipt_td_applicable_attributes_${this.rowNo}_name`,
      decodeURIComponent(name),
      '',
      `receipt[td_applicables_attributes][${this.rowNo}][name]`
    )
    cell.appendChild(input);
  }

  amount(amt, cell) {
    var input = createInputField(
      '',
      `receipt_td_applicable_attributes_${this.rowNo}_amount`,
      amt,
      '',
      `receipt[td_applicables_attributes][${this.rowNo}][amount]`
    )
    input.dataset.invoiceTarget = 'tdAmount';
    input.dataset.action = 'change->invoice#recalculate'
    cell.appendChild(input);
  }

  tdType(type, cell) {
    var input = simpleSelectTag({
      classes: ['form-select', 'form-select-solid'],
      id: `receipt_td_applicable_attributes_${this.rowNo}_td_type`,
      selected: type,
      placeHolder: '',
      name: `receipt[td_applicables_attributes][${this.rowNo}][td_type]`,
      options: {'Percentage': 'percentage', 'Fixed': 'fixed'},
    });
    input.dataset.invoiceTarget = 'tdType';
    input.dataset.action = 'change->invoice#recalculate'
    cell.appendChild(input);
  }

  desc(description, cell) {
    const value = description === "undefined" ? "" : description;
    var input = createInputField(
      '',
      `receipt_td_applicable_attributes_${this.rowNo}_description`,
      value,
      '',
      `receipt[td_applicables_attributes][${this.rowNo}][description]`
    )
    input.required = false;
    cell.appendChild(input);
  }

  actns(value, cell) {
    var btn = deleteBtn();
    btn.dataset.action = 'click->taxes#removeRow';
    if (value) btn.dataset.valueId = value;
    cell.appendChild(btn);
  }

  removeMsgBox() {
    if (this.hasMsgTarget) {
      this.msgTarget.remove();
    }
  }

  renderItemOption(data, uniqueId) {
    const item = data[0];
    const escape = data[1];
    var symbol = null;
    if (item.tdType == 'percentage') {
      symbol = '%'
    } else if (item.tdType == 'fixed') {
      symbol = '-'
    }
    if (item.name === undefined && item.amount === undefined && item.tdType === undefined && item.text !== undefined) {
      return `<div data-id=${escape(item.text)}>${escape(item.text)}</div>`
    } else {
      return `<div data-name=${encodeURIComponent(item.name)} data-td-id=${escape(item.tdId)} data-amount=${escape(item.amount)} data-td-type=${escape(item.tdType)} data-description=${encodeURIComponent(item.description)}>${escape(item.text)} ${escape(item.amount)} ${escape(symbol)}</div>`;
    }
  }

  optionAdd(...args) {
    const selected = Object.assign({}, args[0].dataset);
    if (selected.name === undefined && selected.tdType === undefined && selected.amount === undefined) {
      this.insertNewTr(args[0], args[1]);
    } else if (
      selected.value !== undefined &&
      selected.name !== undefined &&
      selected.tdType !== undefined &&
      selected.amount !== undefined
    ) {
      this.insertTr(selected)
    }
    this.recalculate()
  }

  get enableTs() {
    const uniqueId = Date.now()
    let renderOption = () => (...args) => this.renderItemOption(args, uniqueId);
    let eventHandler = () => (...args) => this.optionAdd(args[1], uniqueId);
    const id = `#${this.inputTarget.id}`;
    this.tsControl = new TomSelect(id, {
      valueField: 'id',
      onItemAdd: eventHandler(),
      closeAfterSelect: true,
      create: true,
      render: {
        option: renderOption(),
        item: renderOption()
      }
    });
    return this.tsControl;
  }

  get rowNo() {
    return this.outputTargets.length;
  }

  insertMessageBox() {
    const messageRow = this.bodyTarget.insertRow(0);
    const messageCell = messageRow.insertCell(0);
    messageCell.innerHTML = 'Select or create tax and discounts for the invoice';
    messageCell.colSpan = '6';
    messageCell.classList.add('text-center');
    messageRow.dataset.taxesTarget = 'msg';
  }

  reInsertMsgBox(event) {
    if (!this.hasOutputTarget) {
      this.insertMessageBox();
    }
  }

  deleteItem(item) {
    item.remove();
  }

  removeRow(event) {
    var removedId = null;
    if(this.hasOutputTarget) {
      var item = event.currentTarget.parentElement.parentElement;
      if (event.currentTarget.dataset.valueId) {
        removedId = event.currentTarget.dataset.valueId;
      } else {
        const items = [...(this.outputTarget.cells)]
          .slice(0, 3)
          .map((cell) => cell.lastElementChild.value)
          .filter(e => e);
        removedId = items.length > 1 ? items.join(';') : items.join('');
      }
      this.recalculate(this.deleteItem(item));

      if (removedId) {
        var itemsRemoval = this.tsControl.items.filter((item) => { return item == removedId });
        itemsRemoval.forEach((item, i) => {
          this.tsControl.removeItem(item);
        });
      }
    }
    this.reInsertMsgBox(event);
  }

  recalculate(callback) {
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("rows-changed", true, true, {});
    this.bodyTarget.dispatchEvent(event);
    callback;
  }
}
