"use strict";
import ApexCharts from 'apexcharts'
import { map } from 'lodash';

export const renderTopPerformingProductsGraph = (element, productData) => {
  return new ApexCharts(
    element,
    topPerformingProductsGraphConfiguration(productData)
  ).render();
}

function topPerformingProductsGraphConfiguration(productData) {
  let productNames = map(productData, e => e[0]);
  let percentage = map(productData, e => e[1]);

  return {
    series: percentage,
    chart: {
      height: 390,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
          }
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
    },
    colors: ['#3E97FF', '#F1416C', '#50CD89', '#FFC700', '#7239EA'],
    labels: productNames,
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'right',
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: (seriesName, _opts) => {
        var productName = '';
        if (seriesName.length > 20) {
          productName = [`${seriesName.substring(0, 18)}` + '..'];
        } else {
          productName = [seriesName];
        }
        return productName;
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: true
        }
      }
    }]
  };
};
