"use strict";

// GraphTooltipTemplate is a class to handle different conditions in creating tooltip template on graphs
export class GraphTooltipTemplate {
  #template = '';

  constructor(title, amount, profitLoss, colIndex, currency) {
    this.title = title;
    this.amount = amount;
    this.profitLoss = parseFloat(profitLoss);
    this.colIndex = colIndex;
    this.currency = currency;
  }

  tooltip() {
    this.#template = '<div>' + this.#prepareTemplate() + '</div>';

    return this.#template;
  }

  #prepareTemplate() {
    return '<div class="scatter-tooltip-header"><b> ' + this.title + '</b>' + '</div>' +
      '<div class="scatter-tooltip-body">' +
      '<div class="scatter-content">' +
      this.#content() +
      '</div>' +
      '</div>'+
      '</div>';
  }

  #content() {
    if(this.colIndex === 0) {
      return '<b>Cost</b>: ' + this.currency + ' ' + this.amount + this.#profitLossDiv()
    } else if(this.colIndex === 1) {
      return '<b>Selling</b>: ' + this.currency + ' ' + this.amount + this.#profitLossDiv()
    }
  }

  // If profit is negative it will show loss and vice-versa.
  // If it is neutral we need to show nothing.
  #profitLossDiv() {
    if(this.profitLoss > 0) {
      return '<br><b>Profit</b>: ' + this.currency + ' ' + this.profitLoss
    } else if(this.profitLoss < 0) {
      return '<br><b>Loss</b>: ' + this.currency + ' ' + this.profitLoss
    } else {
      return ''
    }
  }
}
