import { Tooltip, Popover } from 'bootstrap'

const HSCore = {
  init: () => {
	// Botostrap Tootltips
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new Tooltip(tooltipTriggerEl)
	})

	// Bootstrap Popovers
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
		return new Popover(popoverTriggerEl)
	})
  },
  components: {}
}

window.addEventListener("load", function() {
  HSCore.init()
});
