import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'add'];

  addNewRow(event) {
    event.preventDefault();
    var regexp = undefined;
    var time = undefined;
    time = (new Date).getTime();
    var data = this.addTarget.dataset;
    regexp = new RegExp(data.id, 'g');
    var lineItemsTable = this.formTarget;
    this.cleanUpTheMessageBox(lineItemsTable);
    var row = lineItemsTable.insertRow();
    row.innerHTML = data.fields.replace(regexp, time);
  };

  insertMessageBox(boxEl) {
    if (boxEl.rows.length === 0) {
      var row = boxEl.insertRow(0);
      var cell1 = row.insertCell(0);
      row.id = 'message-box';
      cell1.innerHTML = "Click on 'Add more products' button to add products";
      cell1.colSpan = 4;
      cell1.className = 'text-center';
    }
  };

  attachEventHandlers(boxEl) {
    var pageAction = ['edit', 'update'];
    var actions = ['index', 'edit', 'new'];
    if (_.includes(pageAction, actions)) {
      return _.forEach(boxEl.rows, (row) => {
        if (_.includes(row.cells[3].lastElementChild.classList, 'remove_fields')) {
          return this.removeRowBtn(row.cells[3].lastElementChild, boxEl);
        }
      });
    }
  };

  removeRowBtn(event) {
    event.preventDefault()
    var deleteBtn = event.target;
    var tableObj = this.formTarget;
    if (_.includes(deleteBtn.classList, 'existing')) {
      deleteBtn.parentElement.firstElementChild.value = "1";
      deleteBtn.closest('tr').style.display = 'none';
    } else {
      deleteBtn.closest('tr').remove();
    }
    return this.insertMessageBox(tableObj);
  };

  cleanUpTheMessageBox(tblObj) {
    if (tblObj.rows[0] && (tblObj.rows[0].id === 'message-box')) {
      tblObj.deleteRow(0);
    }
  };
}
