import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { forEach } from "lodash";
import { createInputField, createUnitSelectTag, deleteBtn, taxDiscountSelectTag, hiddenInputField } from 'packs/utility/forms_helper';
const LI_COLS = 7;
const PERCENTAGE = 'percentage';
const FIXED = 'fixed';

export default class extends Controller {
  static targets = ['input', 'output', 'body', 'msg', 'unitRate', 'qty', 'td', 'total'];
  static values = { taxes: String, discounts: String, lis: String };
  tsControl = null;

  connect() {
    this.ts;
    if (!this.hasOutputTarget) {
      this.insertMessageBox();
    }
    this.reBuildForm();
  }

  get tds() {
    return {
      taxes: JSON.parse(this.taxesValue),
      discounts: JSON.parse(this.discountsValue)
    };
  }

  // Rebuild line items form after server side validation error redirect
  // and on receipts edit form
  reBuildForm() {
    if (this.hasLisValue) {
      const lis = JSON.parse(this.lisValue);
      forEach(lis, (item, _i) => {
        this.insertExistingTr(item, this.tds)
      });
      const event = document.createEvent("CustomEvent");
      event.initCustomEvent("inner-connected", true, true, null);
      window.dispatchEvent(event);
    }
  }

  insertExistingTr(item, tds) {
    this.removeMsgBox();
    var row = this.createRowAndInsertCells();
    this.itemName(item.item_name, row.cells[0]);
    this.unitRate(item.unit_rate, row.cells[1]);
    this.qty(item.quantity, row.cells[2]);
    this.unit(item.unit, row.cells[3]);
    this.taxDiscount(row.cells[4], item.td_applicables);
    this.displayTotal(row.cells[5]);
    this.hiddenProductIdField(item.product_id, row);
    if (location.pathname !== '/receipts/new') {
      this.hiddenIdField(item.id, row);
    }
    this.actns(item.product_id, row.cells[6]);
  }

  hiddenIdField(liId, row) {
    var input = hiddenInputField(
      `receipt[line_items_attributes][${this.rowNo}][id]`,
      liId,
      `receipt_line_items_attributes_${this.rowNo}_id`
    )
    row.appendChild(input);
  }

  insertNewTr(args, tds) {
    this.removeMsgBox();
    var row = this.createRowAndInsertCells();
    var opts = [];
    [opts[0], opts[1], opts[2]] = args.innerText.split(';');
    opts[3] = ''; opts[4] = '';
    this.itemName(opts[0], row.cells[0]);
    this.unitRate(opts[1], row.cells[1]);
    this.qty(opts[2], row.cells[2]);
    this.unit(opts[3], row.cells[3]);
    this.taxDiscount(row.cells[4]);
    this.displayTotal(row.cells[5]);
    this.actns(opts[4], row.cells[6]);
  }

  insertTr(option, tds) {
    this.removeMsgBox();
    var row = this.createRowAndInsertCells();
    this.itemName(option.itemName, row.cells[0]);
    this.unitRate(option.unitRate, row.cells[1]);
    this.qty(option.quantity, row.cells[2]);
    this.unit(option.unit, row.cells[3]);
    this.taxDiscount(row.cells[4]);
    this.displayTotal(row.cells[5]);
    this.hiddenProductIdField(option.productId, row);
    this.actns(option.value, row.cells[6]);
  }

  hiddenProductIdField(productId, row) {
    var input = hiddenInputField(
      `receipt[line_items_attributes][${this.rowNo}][product_id]`,
      productId,
      `receipt_line_items_attributes_${this.rowNo}_product_id`
    )
    row.appendChild(input);
  }

  reInsertMsgBox(event) {
    if (!this.hasOutputTarget) {
      this.insertMessageBox();
    }
  }

  removeMsgBox() {
    if(this.hasMsgTarget) {
      this.msgTarget.remove();
    }
  }

  createRowAndInsertCells() {
    const tBody = this.bodyTarget;
    const row = tBody.insertRow(this.rowNo);
    row.dataset.lineItemsTarget = 'output';
    var i = 0;
    while(i < LI_COLS) {
      row.insertCell(i);
      i++;
    }
    return row;
  }

  itemName(name, cell) {
    var input = createInputField(
      '',
      `receipt_line_items_attributes_${this.rowNo}_item_name`,
      name,
      '',
      `receipt[line_items_attributes][${this.rowNo}][item_name]`
    )
    input.dataset.original = name;
    cell.appendChild(input);
  }

  unitRate(rate, cell) {
    var value = rate || 0;
    var input = createInputField(
      '',
      `receipt_line_items_attributes_${this.rowNo}_unit_rate`,
      value,
      '',
      `receipt[line_items_attributes][${this.rowNo}][unit_rate]`,
      ''
    )
    input.dataset.action = 'change->line-items#updateLITotal change->invoice#recalculate';
    input.dataset.lineItemsTarget = 'unitRate';
    input.dataset.original = value;
    cell.appendChild(input);
  }

  qty(qty, cell) {
    var value = qty || 0;
    var input = createInputField(
      '',
      `receipt_line_items_attributes_${this.rowNo}_quantity`,
      value,
      '',
      `receipt[line_items_attributes][${this.rowNo}][quantity]`,
      'number'
    )
    input.step = '0.1';
    input.dataset.action = 'change->line-items#updateLITotal change->invoice#recalculate';
    input.dataset.lineItemsTarget = 'qty';
    input.dataset.original = value;
    cell.appendChild(input);
  }

  unit(ut, cell) {
    var input = createUnitSelectTag(
      '',
      `receipt_line_items_attributes_${this.rowNo}_unit`,
      ut,
      '',
      `receipt[line_items_attributes][${this.rowNo}][unit]`
    )
    cell.appendChild(input);
    new TomSelect(`#receipt_line_items_attributes_${this.rowNo}_unit`, { create: true, valueField: ut })
  }

  renderItemOption(data) {
    const item = data[0];
    const escape = data[1];
    var symbol = '';

    if (item.tdType != undefined && item.name != undefined && item.tdAmount != undefined) {
      if (item.tdType == 'percentage') {
        symbol = '%'
      } else if (item.tdType == 'fixed') {
        symbol = '-'
      }
      return `<div data-amount="${item.tdAmount}">${escape(item.name)} ${escape(item.tdAmount)} ${escape(symbol)}</div>`;
    }
  }

  updateTaxTarget(data, cell) {
    var rowIndex = cell.parentElement.rowIndex - 1;
    const target = this.totalTargets[rowIndex];
    if (data.dataset.amount > 0.0) {
      target.dataset.taxAdd = true;
    } else {
      target.dataset.taxAdd = false
    }
  }

  makeTaxAddFalsey(data, cell) {
    var rowIndex = cell.parentElement.rowIndex - 1;
    const target = this.totalTargets[rowIndex];
    target.dataset.taxAdd = false;
  }

  // NOTE: Think a better way to create tax and discounts without moving away from the invoice form
  taxDiscount(cell, tdApplicables = null) {
    var input = taxDiscountSelectTag(
      '',
      `receipt_line_items_attributes_${this.rowNo}_tds`,
      this.tds,
      'Select Tax/Discount',
      `receipt[line_items_attributes][${this.rowNo}][tds][]`,
      tdApplicables
    )
    input.dataset.action = 'change->line-items#updateLITotal change->invoice#recalculate';
    input.dataset.lineItemsTarget = 'td';
    cell.appendChild(input);
    let renderOption = () => (...args) => this.renderItemOption(args);
    let eventHandler = () => (...args) => this.updateTaxTarget(args[1], cell);
    let updateDataSet = () => (...args) => this.makeTaxAddFalsey(args[1], cell);
    new TomSelect(`#receipt_line_items_attributes_${this.rowNo}_tds`, {
      valueField: 'id',
      onItemAdd: eventHandler(),
      onItemRemove: updateDataSet(),
      closeAfterSelect: true,
      render: {
        option: renderOption(),
        item: renderOption()
      },
      plugins: ['checkbox_options']
     });
  }

  displayTotal(cell){
    var unitRate = null;
    var qty = null;
    var total = 0.0;
    var rowIndex = cell.parentElement.rowIndex - 1;
    unitRate = parseFloat(this.unitRateTargets[rowIndex].value);
    qty = parseFloat(this.qtyTargets[rowIndex].value);
    total = isNaN(unitRate * qty) ? 0.0 : (unitRate * qty);
    var options = [...(this.tdTargets[rowIndex].selectedOptions)];
    if (options) {
      options.forEach((option, i) => {
        var tdType = option.dataset.tdType;
        var tdAmount = parseFloat(option.dataset.tdAmount);
        if (tdType === PERCENTAGE) {
          total += parseFloat((total / 100) * tdAmount);
        }
        if (tdType === FIXED) {
          total = parseFloat(total + tdAmount);
        }
      });
    }
    cell.classList.add('fw-boldest');
    cell.classList.add('text-gray-800');
    cell.style = 'padding-top:0.6rem';
    cell.dataset.lineItemsTarget = 'total';
    cell.dataset.invoiceTarget = 'totalLi';
    cell.innerText = total.toFixed(2);
  }

  actns(value, cell) {
    var btn = deleteBtn();
    btn.dataset.action = 'click->line-items#removeRow';
    if (value) btn.dataset.valueId = value;
    cell.appendChild(btn);
  }

  optionAdd(...args) {
    const selected = args[0].dataset;
    if (selected.productId == 'undefined') {
      this.insertNewTr(args[0], this.tds);
    }
    else if (
      selected.productId != "undefined" &&
      selected.itemName != "undefined" &&
      selected.unitRate != "undefined" &&
      selected.quantity != "undefined" &&
      selected.unit != "undefined"
    ) {
      this.insertTr(selected, this.tds);
    }
    this.recalculate();
  }

  get ts() {
    const id = `#${this.inputTarget.id}`;
    let eventHandler = () => (...args) => this.optionAdd(args[1]);
    this.tsControl = new TomSelect(id, {
      create: true,
      onItemAdd: eventHandler(),
      closeAfterSelect: true,
      render: {
        option: function (data, escape) {
          return `<div style="background-color: #F5F8FA" "data-item-name="${data.itemName}" data-unit-rate="${data.unitRate}" data-quantity="${data.quantity}" data-product-id="${data.productId}" data-unit="${data.unit}">${data.text}</div>`;
        },
        item: function (item, escape) {
          return `<div id="item_${item.$id}" data-item-name="${item.itemName}" data-unit-rate="${item.unitRate}" data-quantity="${item.quantity}" data-product-id="${item.productId}" data-unit="${item.unit}">${item.text}</div>`;
        }
      }
    });
    return this.tsControl;
  }

  insertMessageBox() {
    const messageRow = this.bodyTarget.insertRow(0);
    const messageCell = messageRow.insertCell(0);
    messageCell.innerHTML = 'Select or create new particulars for the invoice';
    messageCell.colSpan = LI_COLS;
    messageCell.classList.add('text-center');
    messageRow.dataset.lineItemsTarget = 'msg';
  }

  deleteItem(item) {
    item.remove();
  }

  removeRow(event) {
    var removedId = null;
    if(this.hasOutputTarget) {
      var item = event.currentTarget.parentElement.parentElement;
      if (event.currentTarget.dataset.valueId) {
        removedId = event.currentTarget.dataset.valueId;
      } else {
        var rowIndex = event.currentTarget.parentElement.parentElement.rowIndex - 1;
        const items = [...(this.outputTargets[rowIndex].children)]
          .slice(0, 3)
          .map((cell) => cell.lastElementChild.dataset.original)
          .filter((e) => e !== '0')
        removedId = items.length > 1 ? items.join(';') : items.join('');
      }
      this.recalculate(this.deleteItem(item));

      if (removedId) {
        var itemsRemoval = this.tsControl.items.filter((item) => { return item == removedId });
        itemsRemoval.forEach((item, i) => {
          this.tsControl.removeItem(item);
        });
      }
    }
    this.reInsertMsgBox(event);
  }

  get rowNo() {
    return this.outputTargets.length;
  }

  updateLITotal(event) {
    var rowIndex = event.target.parentElement.parentElement.rowIndex - 1;
    var unitRate = null;
    var qty = null;
    var total = 0.0;
    unitRate = parseFloat(this.unitRateTargets[rowIndex].value);
    qty = parseFloat(this.qtyTargets[rowIndex].value);
    total = isNaN(unitRate * qty) ? 0.0 : (unitRate * qty);
    if (this.hasTdTarget) {
      var options = [...(this.tdTargets[rowIndex].selectedOptions)];
      if (options) {
        options.forEach((option, i) => {
          var tdType = option.dataset.tdType;
          var tdAmount = parseFloat(option.dataset.tdAmount);
          if (tdType === PERCENTAGE) {
            total += parseFloat((total / 100) * tdAmount);
          }
          if (tdType === FIXED) {
            total = parseFloat(total + tdAmount);
          }
        });
      }
    }
    this.totalTargets[rowIndex].textContent = total.toFixed(2);
  }

  recalculate(callback) {
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("rows-changed", true, true, {});
    this.bodyTarget.dispatchEvent(event);
    callback;
  }
}
