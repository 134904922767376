import Rails from "@rails/ujs"
import { Application } from "stimulus"
import LocalTime from 'local-time'

Rails.start()
LocalTime.start()

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import '../packs/front/observers/abstract.js'
import '../packs/front/observers/floating.js'
import '../packs/front/observers/has-hidden-element.js'
import '../packs/front/observers/hide-section.js'
import '../packs/front/observers/moment-show-hide.js'
import '../packs/front/observers/show-hide.js'
import '../packs/front/observers/sticky.js'
import '../packs/front/observers/without-behavior.js'
import '../packs/front/hs-header.js'
import '../packs/front/hs-sticky-block.js'
import '../packs/front/hs-scrollspy.js'
import './front/bs-utility-init.js'

// stimulus controllers
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./controllers/", true, /\.js$/)
application.load(definitionsFromContext(context))

import('../stylesheets/home_base');
