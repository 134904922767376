import { Controller } from 'stimulus';
import { sumBy } from 'lodash';
import flatpickr from 'flatpickr';

const SUMMARY_ROWS = 6;
const SUMMARY_COLS = 2;
const TITLE_CLASSES = ['text-gray-600'];
const AMOUNT_CLASSES = ['fw-boldest'];
const SUMMARY_CONFIGS = [
  { text: 'Subtotal', default: 0.0, target: 'subtotal' },
  { text: 'Tax(%)', default: 0.0, target: 'taxPerc' },
  { text: 'Tax(/)', default: 0.0, target: 'taxFixed' },
  { text: 'Discount(%)', default: 0.0, target: 'discountPerc' },
  { text: 'Discount(/)', default: 0.0, target: 'discountFixed' },
  { text: 'Total', default: 0.0 , target: 'total'}
];

export default class extends Controller {
  static targets = [
    'summary', 'totalLi', 'tdAmount', 'tdType', 'subtotal',
    'taxPerc', 'taxFixed', 'discountPerc', 'discountFixed', 'total',
    'customCurrency', 'currentCurrency', 'clientCurrency'
  ];
  static values = { currency: String, currencyCode: String };

  connect(event) {
    this.prepareSummaryTable();
    this.recalculate();
    if (this.hasCustomCurrencyTarget) {
      const selectedCurrency = this.customCurrencyTarget.selectedOptions[0].dataset.currencySymbol;
      this.updateCurrencyTargets(selectedCurrency);
    }
  }

  // Gets called on every change in line items fields, tax discounts fields and currency change
  // Recalculates Subtotal, total tax, total discount and total amount of the invoice
  recalculate() {
    var invoiceTotal = 0.0;
    var taxFixed = 0.0;
    var taxPerc = 0.0;
    var discountPerc = 0.0;
    var discountFixed = 0.0;

    var liAmt = sumBy(this.totalLiTargets, (li) => {
      return (parseFloat(li.parentNode.cells[1].childNodes[0].value) * parseFloat(li.parentNode.cells[2].childNodes[0].value));
    });

    // All line items which are already taxed
    var taxedLi = sumBy(this.totalLiTargets, (li) => {
      if(li.dataset.taxAdd === 'true') {
        return parseFloat(li.innerText);
      } else {
        return 0.0
      }
    });

    this.subtotalTarget.innerHTML = `${this.withCurrency(liAmt.toFixed(2))}`;

    this.tdAmountTargets.forEach((tdAmt, i) => {
      const type = [...this.tdTypeTargets[i].selectedOptions][0].value;
      const tdValue = parseFloat(tdAmt.value);
      if (tdValue) {
        if (type === 'percentage'){
          if (tdValue > 0) {
            taxPerc += tdValue;
          } else if (tdValue < 0) {
            discountPerc += tdValue;
          }
        }
        if (type === 'fixed'){
          if (tdValue > 0) {
            taxFixed += tdValue;
          } else if (tdValue < 0) {
            discountFixed += tdValue;
          }
        }
      }
    });

    var newTotalLi = liAmt - taxedLi;

    var totalTaxPerc = parseFloat((newTotalLi / 100) * taxPerc);

    this.taxPercTarget.innerHTML = `${this.withCurrency(totalTaxPerc.toFixed(2))}`;
    this.taxFixedTarget.innerHTML = `${this.withCurrency(taxFixed.toFixed(2))}`;

    var totalDiscountPerc = parseFloat((liAmt / 100) * discountPerc);
    this.discountPercTarget.innerHTML = `${this.withCurrency(totalDiscountPerc.toFixed(2))}`;
    this.discountFixedTarget.innerHTML = `${this.withCurrency(discountFixed.toFixed(2))}`;

    this.totalTarget.innerHTML = `${this.withCurrency(parseFloat(liAmt + totalTaxPerc + taxFixed + totalDiscountPerc + discountFixed).toFixed(2))}`;
  }

  prepareSummaryTable() {
    if (this.summaryTarget.children.length == 0){
      const tBody = this.summaryTarget;
      for(var rowNo = 0; rowNo < SUMMARY_ROWS; rowNo++){
        var row = tBody.insertRow(rowNo);
        for(var cellNo = 0; cellNo < SUMMARY_COLS; cellNo++){
          var cell = row.insertCell(cellNo);
          if(cellNo === 0) {
            cell.innerText = SUMMARY_CONFIGS[rowNo].text;
            this.addClasses(cell, TITLE_CLASSES);
          }
          if(cellNo === 1) {
            cell.dataset.invoiceTarget = SUMMARY_CONFIGS[rowNo].target;
            cell.innerText = `${this.withCurrency(SUMMARY_CONFIGS[rowNo].default)}`;
            this.addClasses(cell, AMOUNT_CLASSES);
          }
        }
      }
    }
  }

  addClasses(cell, klasses) {
    klasses.forEach((klass, _i) => {
      cell.classList.add(klass)
    });
  }

  withCurrency(amount) {
    if (this.currencyCodeValue.toLowerCase() === this.customCurrencyTarget.selectedOptions[0].value) {
      return `<span class="fs-7">${this.currencyValue}</span> ${amount}`
    } else if (this.currencyCodeValue.toLowerCase() !== this.customCurrencyTarget.selectedOptions[0].value) {
      return `<span class="fs-7">${this.customCurrencyTarget.selectedOptions[0].dataset.currencySymbol}</span> ${amount}`
    }
  }

  changeCurrency(event) {
    const selectedCurrency = event.target.selectedOptions[0].dataset.currencySymbol;
    this.customCurrencyTarget.value = event.target.selectedOptions[0].dataset.currencyCode;
    this.recalculate();
    this.updateCurrencyTargets(selectedCurrency);
  }

  updateCurrencyTargets(symbol) {
    this.currentCurrencyTargets.forEach((target, _i) => {
      target.textContent = `(${symbol})`
    });
  }
}
