"use strict";

export class ProductGraphHandler {
  constructor(targets, data, currencyValue) {
    this.els = targets;
    this.data = data;
    this.currency = currencyValue;
  }

  setup(selectedMonth) {
    this.els.product.graphEl.innerHTML = '';
    this.els.earning.mainTitleEl.style = 'display: none !important';
    this.els.earning.graphEl.style = 'display: none';
    this.els.product.graphEl.style = 'display: block';
    this.els.product.backBtnEl.style = 'display: block';
    this.els.product.selectedMonthEl.innerHTML = this.#selectedMonthCaption(selectedMonth);
    this.els.product.captionEl.style = 'display: block';
    this.#displayProductCaption();
  }

  #selectedMonthCaption(selectedMonth) {
    return `<span class='fs-2hx fw-bolder text-gray-800 me-2 lh-1'> ${selectedMonth} </span>`
  }

  #displayProductCaption() {
    if (parseFloat(this.#totalProfit()) > 0) {
      return this.els.product.captionEl.innerHTML = `Profit ${this.currency}${this.#totalProfit()}`;
    } else if (parseFloat(this.#totalProfit()) < 0) {
      return this.els.product.captionEl.innerHTML = `Loss ${this.currency}${this.#totalProfit()}`;
    }
  }

  #totalProfit() {
    return this.data.profits.reduce((a, b) => a + b);
  }
}
