import { Controller } from "stimulus"

// This controller is intended to display notice/message/hints based on the
// state of certain input fields.
export default class extends Controller {
  static targets = ['message', 'address', 'state', 'city', 'zip'];

  connect(){
    if (window.location.hash === '#account_settings_info') {
      if (this.addressTarget.value === '' || this.stateTarget.value === '' || this.cityTarget.value === '' || this.zipTarget.value === '') {
        window.scrollTo(0,document.querySelector("#account_settings_info").scrollHeight, {behaviour: 'smooth'});
        this.messageTarget.style.display = 'block'
      }
    }
  }
}
